export const mapEnvironment = {
  mapSettings: {
    defaultStyle: 'accuterra-dark-grey',
    attribution:
      '<a href="https://accuterra.com/" target="_blank">© AccuTerra</a>',
    defaultExtent: [-128.0711, 16.563075, -63.94282, 55.374338], //lower 48
  },
  mapStyles: [
    {
      id: 'accuterra-outdoors',
      name: 'Outdoors',
      url: 'https://maps.accuterra.com/v1/styles/accuterra-outdoors/style.json',
      thumbUrl: '../assets/images/map-toggle-images/style_topo.webp',
      description: 'AccuTerra Outdoors showcases the unique map content AccuTerra has to offer. Our public lands data, combined with . We show in bright and bold color America’s many public land types so you always know who manages the land you’re on. When combined with our extensive road and trail network and overlaid on our highly detailed terrain data, AccuTerra Outdoors is the perfect companion for your next outdoor adventure. This map is available in both metric and imperial elevation units.',
      tileSourceId: 'accuterra-outdoors-raster'
    },

    {
      id: 'accuterra-topo',
      name: 'Topo',
      url: 'https://maps.accuterra.com/v1/styles/accuterra-topo/style.json',
      thumbUrl: '../assets/images/map-toggle-images/accuterra-topo.webp',
      description: 'AccuTerra Topo is another take on our unique mapping content. With a different styling for roads, trails, and terrain AccuTerra Topo gives you another option for presenting AccuTerra to your users. Topo is well suited for use outside of the United States. This map is available in both metric and imperial elevation units.',
    },
    {
      id: 'accuterra-terrain',
      name: 'Terrain',
      url: 'https://maps.accuterra.com/v1/styles/accuterra-terrain/style.json',
      thumbUrl: '../assets/images/map-toggle-images/accuterra-terrain.webp',
      description: 'A map style that depicts AccuTerra’s terrain data with a variety of colors. This map is available in both metric and imperial elevation units.',
    },
    {
      id: 'light-grey',
      name: 'Light Grey',
      url: 'https://maps.accuterra.com/v1/styles/light-grey/style.json',
      thumbUrl: '../assets/images/map-toggle-images/light-grey.webp',
      description: 'A clean light basemap depicting AccuTerra’s terrain data. This map is available in both metric and imperial elevation units.',
    },
    {
      id: 'dark-grey',
      name: 'Dark Grey',
      url: 'https://maps.accuterra.com/v1/styles/dark-grey/style.json',
      thumbUrl: '../assets/images/map-toggle-images/dark-grey.webp',
      description: 'A clean dark basemap depicting AccuTerra’s terrain data. This map is available in both metric and imperial elevation units.',
    },
    {
      id: 'esri-world-imagery',
      name: 'Satellite',
      url: 'https://maps.accuterra.com/v1/styles/labels/style.json',
      thumbUrl: '../assets/images/map-toggle-images/style_topo.webp',
      description: 'A satellite imagery map style that provides a realistic view of the terrain. This map is available in both metric and imperial elevation units.',
    },
  ],
};

export const mapCodeSamples = [
  {
    name: 'TileJSON',
    code: [
      'Imperial: https://maps.accuterra.com/v1/styles/HERE.json?apiKey=YOUR-MAP-API-KEY-HERE',
      'Metric: https://maps.accuterra.com/v1/styles/HERE-m.json?apiKey=YOUR-MAP-API-KEY-HERE',
    ],
  },
  {
    name: 'XYZ Raster',
    code: [
      'Imperial: https://maps.accuterra.com/v1/data/HERE/{z}/{x}/{y}.jpg?key=YOUR-MAP-API-KEY-HERE',
      'Metric: https://maps.accuterra.com/v1/data/HERE-m/{z}/{x}/{y}.jpg?key=YOUR-MAP-API-KEY-HERE',
    ],
  },
];

import React, { Component } from 'react';

const mapContainerStyle = {
  width: '100%',
  height: '400px',
  zIndex: 1,
};

export default class Leaflet extends Component {
  componentDidMount() {
    setTimeout(() => {

      let mymap = window.L.map('mapid').setView([40.3772, -105.5217], 11);
      var myKey = process.env.GATSBY_ACCUTERRA_API_KEY;
      window.L.tileLayer(
        `https://{s}.accuterra.com/v1/data/accuterra-outdoors-raster/{z}/{x}/{y}.jpg?key=${myKey}`,
        {
          maxZoom: 16,
          attribution: '&copy; AccuTerra Maps',
          subdomains: ['maps-d1', 'maps-d2', 'maps-d3', 'maps-d4'],
        }
      ).addTo(mymap);
    }, 1000);
  }
  render() {
    return <div id="mapid" style={mapContainerStyle}></div>;
  }
}

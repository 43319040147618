import React from 'react';
import { useState } from 'react';
import ReactMapGL from 'react-map-gl';

const MAPBOX_TOKEN = process.env.GATSBY_ENV_MAPBOX_TOKEN;
const ACCUTERRA_STYLE = `https://maps.accuterra.com/v1/styles/accuterra-outdoors/style.json?key=${process.env.GATSBY_ACCUTERRA_API_KEY}`;

export default function MapLibre() {
  const [viewport, setViewport] = useState({
    width: '100%',
    height: '400px',
    latitude: 40.3772,
    longitude: -105.5217,
    zoom: 11,
  });

  return (
    <ReactMapGL
      {...viewport}
      width="100%"
      height="400px"
      mapStyle={ACCUTERRA_STYLE}
      mapboxApiAccessToken={MAPBOX_TOKEN}
      onViewportChange={setViewport}
    />
  );
}
